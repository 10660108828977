import React, { useEffect, useState } from 'react';

import { AiOutlineCheckCircle, AiOutlineCloudUpload } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';

import './DnD.css';

const DragNdrop = ({
  onFilesSelected,
  width,
  height,
  showList
}) => {
  const [files, setFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const newFiles = Array.from(droppedFiles).filter((file) => {
      return file.type === 'text/xml';
    });

    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
    setIsDragOver(false);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (files.length > 0) {
      onFilesSelected(files);
      setFiles([]);
    }
  }, [files, onFilesSelected]);

  return (
    <section className="drag-drop" style={{ width: width, height: height }}>
      <div
        className={`document-uploader ${files.length > 0 ? "upload-box active" : "upload-box"
          } ${isDragOver ? "drag-over" : ""}`}
        onDrop={handleDrop}
        onDragOver={(event) => {
          event.preventDefault();
        }}
        onDragEnter={(event) => {
          setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
      >
        <>
          <div className="upload-info">
            <AiOutlineCloudUpload />
            <div>
              <p>XML-Datei hierher ziehen</p>
            </div>
          </div>
          <input
            type="file"
            name="file"
            id="browse"
            onChange={handleFileChange}
            accept="text/xml"
            multiple
          />
          <label htmlFor="browse" className="browse-btn">
            Datei auswählen
          </label>
        </>

        {files.length > 0 && showList && (
          <div className="file-list">
            <div className="file-list__container">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <div className="file-info">
                    <p>{file.name}</p>
                    {/* <p>{file.type}</p> */}
                  </div>
                  <div className="file-actions">
                    <MdClear onClick={() => handleRemoveFile(index)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 0 && showList && (
          <div className="success-file">
            <AiOutlineCheckCircle
              style={{ color: "#6DC24B", marginRight: 1 }}
            />
            <p>{files.length} file(s) selected</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragNdrop;
