import React, { useState } from 'react';

const Customer = ({ customer }) => {
  const [tab, setTab] = useState('address');

  const {
    name = '', street = '', zip = '', city = '', country = '', registrationname = '',
    delivery = {}, payee = {}, taxrepresentative = {}
  } = customer;

  return (
    <div className="customer">
      <div className={`customer-tab ${tab}`}>
        {(payee.name || delivery.name) && <div
          className="tab-address"
          onClick={() => setTab('address')}
        >
          Adresse
        </div>}
        {delivery.name && <div
          className="tab-delivery"
          onClick={() => setTab('delivery')}
        >
          Liefer-Adresse
        </div>}
        {payee.name && <div
          className="tab-payee"
          onClick={() => setTab('payee')}
        >
          Rechnungs-Adresse
        </div>}
        {taxrepresentative.name && <div
          className="tab-tax"
          onClick={() => setTab('tax')}
        >
          Steuer-Adresse
        </div>}
      </div>

      {tab === 'address' && <div className="address">
        {name && <div>{name}</div>}
        {registrationname && <div>{registrationname}</div>}
        <div>{street}</div>
        <div>{country} {zip} {city}</div>
        <br />
        <div>{customer.contact?.name}</div>
        <div>{customer.contact?.phone}</div>
        <div>{customer.contact?.email}</div>
      </div>}

      {tab === 'delivery' && <div className="deliveryaddress">
        <div>{delivery.name}</div>
        <div>{delivery.street}</div>
        <div>{delivery.country} {delivery.zip} {delivery.city}</div>
      </div>}

      {tab === 'payee' && <div className="payeeaddress">
        <div>{payee.name}</div>
        <div>{payee.street}</div>
        <div>{payee.country} {payee.zip} {payee.city}</div>
      </div>}

      {tab === 'tax' && <div className="taxaddress">
        <div>{taxrepresentative.name}</div>
        <div>{taxrepresentative.street}</div>
        <div>{taxrepresentative.country} {taxrepresentative.zip} {taxrepresentative.city}</div>
        <div>ID: {taxrepresentative.taxid}</div>
      </div>}

    </div>
  )
}

export default Customer
