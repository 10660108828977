import React from 'react';

import { format2decimals } from '../lib/utils';

const Positions = ({ positions, currency }) => {
  return (
    <table className="positions">
      <tr className="tableheader">
        <th className="description">Beschreibung</th>
        <th className="quantity">Menge</th>
        <th className="unit">Einheit</th>
        <th className="tax">MwSt</th>
        <th className="allowance">Rabatt</th>
        <th className="price">Preis</th>
        <th className="postotal">Gesamt</th>
      </tr>
      {positions.map((position, index) => <tr key={index} className="position">
        <td className="description">
          {position.description !== position.name && <div>
            {(position.name || '').split('\n').map(line =>
            <div key={line}>{line}</div>
          )}</div>}
          <div>{(position.description || '').split('\n').map(line =>
            <div key={line}>{line}</div>
          )}</div>
          {(position.note && typeof position.note === 'string') && <div>{position.note.split('\n').map(line =>
            <div key={line}>{line}</div>
          )}</div>}
          {(position.note && typeof Array.isArray(position.note)) && <div>
            {position.note.map(note => note.split('\n').map(line =>
              <div key={line}>{line}</div>
            ))}
          </div>}

          {position.characteristics && <div className='characteristics'>
            {position.characteristics.map((characteristic, index) => <div key={index}>
              {characteristic.name && <div>{characteristic.name}: {characteristic.value}</div>}
            </div>)}
          </div>}

          {position.startdate && <div>von {position.startdate} bis {position.enddate}</div>}
          {position.sellersitemidentification && <div>Bestellnr: {position.sellersitemidentification}</div>}
          {position.globalid && <div>Global-ID: {position.globalid}</div>}
          <div>{position.taxexemptionreason}</div>
        </td>
        <td className="quantity">{position.quantity}</td>
        <td className="unit">{position.basequantity}</td>
        <td className="tax">{format2decimals(position.taxpercent)}%</td>
        <td className="allowance">
          {position.allowance.map((allowance, index) => <div key={index}>
            {allowance.amount ? format2decimals(allowance.amount, currency) : ''}
          </div>)}
        </td>
        <td className="price">{format2decimals(position.price, currency)}</td>
        <td className="postotal">{format2decimals(position.totalamount, currency)}</td>
      </tr>)}
    </table>


  )
}

export default Positions
