import React from 'react';

const References = ({ invoice }) => {
  const {
    number, issuedate, order, contract, project, supplier = {},
    salesorder, referencebuyer, deliverydate, startdate, enddate, documentreference
  } = invoice || {};

  return (
    <div className="data">
      <div className="number">
        <div>Nummer</div>
        <div>{number}</div>
      </div>
      <div className="date">
        <div>Datum</div>
        <div>{issuedate}</div>
      </div>
      {deliverydate && <div className="delivery">
        <div>Lieferdatum</div>
        <div>{deliverydate}</div>
      </div>}
      {startdate && <div className="start">
        <div>von</div>
        <div>{startdate}</div>
      </div>}
      {enddate && <div className="end">
        <div>bis</div>
        <div>{enddate}</div>
      </div>}

      {referencebuyer && <div className="reference">
        <div>Referenz</div>
        <div>{referencebuyer}</div>
      </div>}
      {order && <div className="order">
        <div>Bestellung</div>
        <div>{order}</div>
      </div>}
      {salesorder && <div className="salesorder">
        <div></div>
        <div>{salesorder}</div>
      </div>}
      {contract && <div className="contract">
        <div>Vertrag</div>
        <div>{contract}</div>
      </div>}
      {project && <div className="project">
        <div>Projekt</div>
        <div>{project}</div>
      </div>}
      {documentreference && <div className="documentreference">
        <div>Dokument</div>
        <div>{documentreference}</div>
      </div>}
      {supplier.taxid && <div className="egident">
        <div>EG-Identnr</div>
        <div>{supplier.taxid}</div>
      </div>}
      {supplier.contact?.name && <div className="contact">
        <div>Ansprechpartner</div>
        <div>{supplier.contact?.name}</div>
      </div>}
      {supplier.contact?.phone && <div className="phone">
        <div>Telefon</div>
        <div>{supplier.contact?.phone}</div>
      </div>}
      {supplier.contact?.email && <div className="email">
        <div>Email</div>
        <div>{supplier.contact?.email}</div>
      </div>}
    </div>
  )
}

export default References
