import React from 'react';

const Footer = ({ supplier, document }) => {
  return (
    <div className="footer">
      {supplier.registrationname || supplier.name}  -  {supplier.companyid}  -  {supplier.legalform}
      <br />
      <div className="document">
        {document.id && <div>
          <div className="documentname">Anhang: {document.description || document.id}</div>
          <div>
            <embed
              title={document.id}
              width="100%"
              height="1200px"
              className="documentembed"
              src={`data:application/pdf;base64,${document.binaryobject}`}
              type="application/pdf"
            />
          </div>
        </div>}
      </div>
    </div>
  )
}

export default Footer
