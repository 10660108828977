import React, { useEffect, useState } from 'react';

import { getDoc, uploadFiles } from './lib/utils';

import DnD from './dnd/DnD';
import Customer from './widgets/Customer';
import Footer from './widgets/Footer';
import Notes from './widgets/Notes';
import Payment from './widgets/Payment';
import Positions from './widgets/Positions';
import References from './widgets/References';
import Supplier from './widgets/Supplier';
import Total from './widgets/Total';

import './App.css';

const App = () => {
  const urlQParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(atob(urlQParams.get('_q')));

  const [invoice, setInvoice] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const urlQParams = new URLSearchParams(window.location.search);
    const urlParams = new URLSearchParams(atob(urlQParams.get('_q')));

    if (urlParams.has('token') && urlParams.has('id') && urlParams.has('guid')) {
      getDoc(json => {
        if (json.error) {
          setMessage(json.error);
          return;
        }
        setInvoice(json);
      });
    }
  }, []);

  const handleChange = (files) => {
    uploadFiles(files, json => {
      if (json.error) {
        setMessage(json.error);
        return;
      }
      setInvoice(json);
    });
  };


  const {
    customer = {}, supplier = {}, notes = [], positions = [], total = {}, payment = [], duedate,
    currency, document = {}, paymentinformation, paymentnote
  } = invoice || {};

  return (message ?
    <div className = "error-message">{ message }</div>
    :
    <>
      {!urlParams.has('id') ?
        <form>
          <DnD onFilesSelected={handleChange} />
        </form>
        :
        <div className="invoice-id" />
      }
      {invoice ?
        <div className="paper">
          <div className="header">
            <div className="title">Rechnung</div>
            <Supplier supplier={supplier} />
          </div>

          <div className="content">
            <Customer customer={customer} />
            <References invoice={invoice} />
          </div>

          <Notes notes={notes} />
          <Positions positions={positions} currency={currency} />
          <Total total={total} currency={currency} />

          <Payment
            payment={payment}
            paymentinformation={paymentinformation}
            paymentnote={paymentnote}
            supplier={supplier}
            duedate={duedate}
            currency={currency}
          />

          <Footer supplier={supplier} document={document} />
        </div>
        :
        <div className="loading">
          {urlParams.has('id') ? <div className="loading-spinner" /> : ''}
        </div>
      }
    </>
  );
}

export default App;
