const getConfig = async () => {
  const result = await fetch('/config/env-config.json', { mode: 'no-cors' });
  return JSON.parse(await result.text());
};


const format2decimals = (value, currency) => {
  const num = new Intl.NumberFormat("de-DE", { style: currency ? "currency" : "decimal", currency });
  return num.format(value);
}

const getDoc = async (callback) => {
  const urlQParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(atob(urlQParams.get('_q')));

  const token = urlParams.get('token');
  const id = urlParams.get('id');
  const SOA = urlParams.get('_s');
console.log('SOA', SOA);
  const url = `${SOA}/rest/invoicebyid`;
  const fetchOptions = {
    method: 'post',
    body: JSON.stringify({ id, token }),
    rejectUnauthorized: false
  };

  try {
    const response = await fetch(url, fetchOptions);
    const xml = await response.text();

    const fileName = 'invoice.xml';
    const fileBlob = new Blob([xml], { type: 'text/xml' });

    const file = new File([fileBlob], fileName, { type: 'text/xml' });
    uploadFiles([file], callback);

  } catch (error) {
    console.error(error.message);
  }
}

const getXML = async (callback) => {
  const urlQParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(atob(urlQParams.get('_q')));

  const token = urlParams.get('token');
  const id = urlParams.get('id');
  const guid = urlParams.get('guid');

  const { SERVER } = await getConfig();

  const url = `${SERVER}/xml`;
  const fetchOptions = {
    method: 'post',
    body: JSON.stringify({ token, id }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + guid
    }
  };

  try {
    const response = await fetch(url, fetchOptions);

    const json = await response.json();
    if (typeof callback === 'function') {
      callback(json);
    }
  } catch (error) {
    console.error(error.message);
  }
}

const uploadFiles = async (files = [], callback) => {
  const urlQParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(atob(urlQParams.get('_q')));

console.log('urlParams', urlParams, urlQParams);

  const { SERVER } = await getConfig();

  const url = `${SERVER}/upload`;
  const formData = new FormData();
  formData.append('file', files[0]);

  const fetchOptions = {
    method: 'post',
    body: formData,
    headers: {
      'Authorization': 'Bearer ' + urlParams.get('guid')
    }
  };

  try {
    const response = await fetch(url, fetchOptions);

    const json = await response.json();
    if (typeof callback === 'function') {
      callback(json);
    }
  } catch (error) {
    console.error(error.message);
  }
}

export { format2decimals, getDoc, getXML, uploadFiles };
